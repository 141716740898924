.bb>svg {
  font: inherit;
}

.bb-xgrid-line > line {
  stroke: inherit !important;
  stroke-width: 4px;
}

.bb-region {
  rect {
    width: 25%;
    fill: var(--v-primary-base);
    fill-opacity: 0.3;
  }

  &.relational {
    transform: translateX(25%);
  }

  &.action {
    transform: translateX(50%);
  }

  &.order {
    transform: translateX(75%);
  }
}

.bb-x {
  &.idea {
    stroke: var(--v-ideas-base);
  }

  &.relational {
    stroke: var(--v-relational-base);
  }

  &.action {
    stroke: var(--v-action-base);
  }

  &.order {
    stroke: var(--v-order-base);
  }
}

.bb-lines {
  stroke-width: 2px;
}

.bb-text {
  fill: white !important;
  font-weight: bold;
  transform: translateY(23px);
}

#chart {
  width: 100%;
}

.font-weight-bold {
  font-weight: bold;
}

.relational--text {
  font-size: 14px;
  color: var(--v-relational-base);
}

.ideas--text {
  font-size: 14px;
  color: var(--v-ideas-base);
}

.action--text {
  font-size: 14px;
  color: var(--v-action-base);
}

.order--text {
  font-size: 14px;
  color: var(--v-order-base);
}

.ts-chart-tooltip {
  max-width: 300px; 
  background-color:#444; 
  border-radius: 10px; 
  font-size: 14px; 
  overflow: hidden; 
  color: white;

  &-title {
    font-weight: bold; 
    text-align: center; 
    background-color: #5F5F5F; 
    padding: 6px 30px 6px 6px;    
    font-size: 15px;
  }
  
  &-score {
    margin-right: 5px;
    color: #ccc;
  }

  &-body {
    padding: 10px 15px 15px 15px; 
    margin: 0px;
  }
}

.ts-chart-header {
  background-color: #F0F0F0;
  border-radius: 12px;
  border-spacing: 4px;
  border-collapse: separate;  

  & td {
    padding: 3px 8px;
    vertical-align: top;
    line-height: 19px;
    text-align: center;
  }

  & .ts-chart-header-titles td {
    font-weight: bold;
    padding-bottom: 0px;
    // text-align: center;
  }
}

.ts-chart {
  background-color: #FFF;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #CCC;
}

.bb-text {
  pointer-events: none;
}

.bb-xgrid-focus {
  display: none !important;
}

.bb-tooltip-container {
  opacity: 0;
  transition: opacity .7s;
}

g.bb-header {
  &.idea {
    text {
      fill: var(--v-ideas-base);      
    }    
    transform: translate(.5%, -2.5%);
  }

  &.idea line {
    transform: translate(-.5%, 2.5%);
  }

  &.relational {
    text {
      fill: var(--v-relational-base);
    }
    transform: translate(25.5%, -2.5%);
  }

  &.relational line {
    transform: translate(-25.5%, 2.5%);
  }

  &.action {
    text {
      fill: var(--v-action-base);
    }
    transform: translate(50.5%, -2.5%);
  }

  &.action line {
    transform: translate(-50.5%, 2.5%);
  }

  &.order {
    text {
      fill: var(--v-order-base);
    }
    transform: translate(75.5%, -2.5%);
  }

  &.order line {
    transform: translate(-75.5%, 2.5%);
  }
}

g.bb-header-bottom {
  &.idea {
    text {
      fill: var(--v-ideas-base);  
    }
    transform: translate(.5%, 10%);
  }

  &.idea line {
    text {
      fill: var(--v-ideas-base);  
    }
    transform: translate(-.5%, -10%);
  }

  &.relational {
    text {
      fill: var(--v-relational-base);
    }
    transform: translate(25.5%, 10%);
  }

  &.relational line {
    transform: translate(-25.5%, -10%);
  }

  &.action {
    text {
      fill: var(--v-action-base);
    }
    transform: translate(50.5%, 10%);
  }

  &.action line {
    transform: translate(-50.5%, -10%);
  }

  &.order {
    text {
      fill: var(--v-order-base);
    }
    transform: translate(75.5%, 10%);
  }

  &.order line {
    transform: translate(-75.5%, -10%);
  }
}

g.bb-label {
  font-weight: 400;
  font-size: 12px; // Added    

  &.ideas  {
    transform: translate(.5%, -2.5%);
  }

  &.ideas line  {
    transform: translate(-.5%, 2.5%);
  }
  
  &.relational  {
    transform: translate(25.5%, -2.5%);
  }
  
  &.relational line  {
    transform: translate(-25.5%, 2.5%);
  }
  
  &.action  {
    transform: translate(50.5%, -2.5%);
  }
  
  &.action line  {
    transform: translate(-50.5%, 2.5%);
  }
  
  &.order  {
    transform: translate(75.5%, -2.5%);
  }
  
  &.order line  {
    transform: translate(-75.5%, 2.5%);  } 
}

:root {
  // Dark
  // --v-primary-base: #656B9D;
  // --v-secondary-base: #22618A;
  // --v-tertiary-base: #C58073;
  // --v-accent-base: #DA9150;
  // --v-dark-base: #050505;
  // --v-danger-base: #E87270;
  // --v-info-base: #98E3F9;
  // --v-success-base: #96DCB7;
  // --v-warning-base: #FDA4BC;
  // --v-ideas-base: #B94755;
  // --v-relational-base: #2A2A86;
  // --v-action-base: #351109;
  // --v-order-base: #001725;

  // Light
  --v-primary-base: #5D7AF9;
  --v-secondary-base: #AAB9CE;
  --v-tertiary-base: #F1A485;
  --v-accent-base: #FCC570;
  --v-dark-base: #656B9D;
  --v-danger-base: #E87270;
  --v-info-base: #98E3F9;
  --v-success-base: #96DCB7;
  --v-warning-base: #FDA4BC;
  --v-ideas-base: #344FC9;
  --v-relational-base: #3A7C8F;
  --v-action-base: #539773;
  --v-order-base: #697E98;
}

.tsTooltip {
  position:absolute;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity .7s;
}