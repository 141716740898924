/* custom scrollbar */
::-webkit-scrollbar {
  width: 22px;
  height: 22px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d5e5f2;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a6ceee;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
